import { createContext, useContext } from "react";

export const AppContext = createContext({
  appData: {},
  setAppData: () => {}, // Provide a default implementation
});

export const AppProvider = AppContext.Provider;

export default function useApp() {
  return useContext(AppContext);
}
