import React from "react";

const Section = ({ children, className }) => {
  return (
    <section
      className={`h-full w-full px-0 md:px-5 py-8 md:py-12 bg-primary ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
