import React from "react";
import { Link } from "react-router-dom";
import Icons, { ICON_TYPES } from "./IconLoader";

const Heading = ({
  type,
  heading,
  title,
  modelTitle,
  subTitle,
  iconSize,
  toLink,
  className,
}) => {
  return (
    <>
      {type === "logo" && (
        <Link
          to={toLink}
          className={`flex items-center cursor-pointer gap-2 ${className}`}
        >
          <Icons name={ICON_TYPES.Logo} size={iconSize} />
          {title && (
            <span className="font-bold italic text-2xl capitalize">
              {title}
            </span>
          )}
        </Link>
      )}
      {type === "heading" && (
        <article className={`flex flex-col gap-y-2 ${className}`}>
          {heading && (
            <div className="text-lg md:text-xl font-medium opacity-60">
              {heading}
            </div>
          )}
          {title && (
            <div className="text-[28px] md:text-[34px] font-semibold leading-tight">
              {title}
            </div>
          )}
          {modelTitle && (
            <div className="text-5xl sm:text-[50px] md:text-[80px] xl:text-[120px]  uppercase text-bg/50 font-Tourney font-bold italic leading-tight shadow-text whitespace-nowrap">
              {modelTitle}
            </div>
          )}
          {subTitle && (
            <div
              className="text-md md:text-lg font-light leading-tight "
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          )}
        </article>
      )}
    </>
  );
};

export default Heading;
