import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import DroneAnimation from "../assets/json/Drone-Animation.json";
export default function ErrorPage() {
  const [errorInfo, setErrorInfo] = useState(null);
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      // Extract information from the error object
      const { message, stack, status, statusText } = error;
      const stackLines = stack ? stack.split("\n") : [];
      const firstStackLine = stackLines[1] || "";

      // Extract file name and line number from the stack trace
      const matches = firstStackLine.match(/\((.*):(\d+):(\d+)\)/);
      const fileName = matches ? matches[1] : "";
      const lineNumber = matches ? matches[2] : "";

      // Store error information in state
      setErrorInfo({
        status,
        fileName,
        statusText: statusText || message,
        lineNumber,
      });
    }
  }, [error]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <Lottie
        className="absolute h-full w-5/12 p-0 m-0"
        loop
        animationData={DroneAnimation}
      />
      <div className="flex flex-col items-center justify-center z-0">
        <p className="text-5xl md:text-6xl lg:text-7xl font-bold tracking-wider text-primary mt-8">
          {errorInfo?.status}
        </p>
        <p className="text-xl md:text-2xl font-bold text-primary/60 mt-2">
          {errorInfo?.statusText}&nbsp;
          {errorInfo?.lineNumber ? `Line No: ${errorInfo?.lineNumber}` : ""}
        </p>
        <p className="md:text-lg xl:text-xl text-primary/40 mt-4">
          Whoops, something went wrong on our servers.
        </p>
      </div>
    </div>
  );
}
