export const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
      transition: {
        type: "tween",
        duration: 1.5,
        delay: delay,
        ease: [0.25, 0.6, 0.3, 0.8],
      },
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 1.4,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

export const fadeOut = (direction, delay) => {};

export const sliderSettings = ({
  dots,
  arrows,
  infinite,
  slidesToShow,
  autoplay,
  breakpoints,
  speed,
  cssEase,
}) => {
  const responsiveSettings = breakpoints
    ? Object.entries(breakpoints).map(([key, value]) => ({
        breakpoint: value,
        settings: {
          slidesToShow: key === "xs" || key === "sm" ? 1 : 2, // Adjust slidesToShow based on breakpoints
          slidesToScroll: 1,
          ...(key === "xs" && { arrows: false }), // Customize settings for specific breakpoints
        },
      }))
    : [];

  return {
    dots,
    arrows,
    infinite,
    speed: speed | 800,
    slidesToShow,
    slidesToScroll: 1,
    autoplay,
    autoplaySpeed: speed | 4000,
    cssEase: cssEase | "ease-in-out",
    pauseOnHover: false,
    pauseOnFocus: true,
    responsive: responsiveSettings,
  };
};
