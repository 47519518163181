import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Button, Heading } from "../../shared";
import { sliderSettings } from "../../utils";

const ProductCard = ({ data, type }) => {
  const {
    _id,
    _type,
    name,
    model,
    subTitle,
    description,
    price,
    cardImage,
    videoURL,
    videoTitle,
    images,
    isPopular,
  } = data;

  const [imageSrc, setImageSrc] = useState(null);
  const [imageSrcArr, setImageSrcArr] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      if (!images) return; // Check if images is undefined

      const loadedImages = await Promise.all(
        images.map(async (img) => {
          try {
            // Dynamically import the image
            const module = await import(`../../assets/images/${img}`);
            return module.default;
          } catch (error) {
            console.error("Error loading image:", error);
            return null;
          }
        })
      );

      // Filter out null values and add an empty image at the end
      setImageSrcArr([...loadedImages.filter(Boolean)]);
    };

    loadImages();
  }, [images]);

  useEffect(() => {
    // Dynamically import the image
    import(`../../assets/images/${cardImage}`)
      .then((module) => {
        // Set the image source once it's loaded
        setImageSrc(module.default);
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
  }, [cardImage]);

  const settings = sliderSettings({
    dots: false,
    arrows: false,
    infinite: imageSrcArr.length > 1 ? true : false,
    slidesToShow: 1,
    autoplay: imageSrcArr.length > 1 ? true : false,
  });

  return (
    <>
      {type === "hover-details" && (
        <section className="m-auto group product card h-96 w-full md:w-96 relative cursor-pointer overflow-hidden transition-shadow hover:shadow-xl hover:shadow-shadow/30 rounded-2xl border border-secondary">
          {imageSrcArr?.length > 0 && (
            <Slider {...settings}>
              {imageSrcArr?.map((src, index) => (
                <img
                  key={index}
                  className="absolute inset-0 images h-96 w-full object-cover group-hover:object-center bg-primary overflow-hidden"
                  src={src}
                  alt="DJI"
                />
              ))}
              {!imageSrcArr?.length > 0 && (
                <img
                  src={imageSrc}
                  alt={data.name}
                  className="absolute inset-0 images h-96 w-full object-cover group-hover:object-center bg-primary overflow-hidden"
                />
              )}
            </Slider>
          )}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-shadow/40 group-hover:from-shadow/70 group-hover:via-shadow/60 group-hover:to-shadow/70"></div>
          <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-700 group-hover:translate-y-0">
            <h1 className="text-3xl font-bold">{name}</h1>
            <p className="mb-3 text-lg italic opacity-0 transition-opacity duration-700 group-hover:opacity-100">
              {description}
            </p>
            <Button
              name="More Info"
              className={"px-8 py-2 border border-primary rounded-xl"}
              toLink={`/collection/${_id}/${_type}`}
            />
          </div>
        </section>
      )}
      {type === "side-details" && (
        <Link
          to={_id ? `/collection/${_id}/${_type}` : "#"}
          className="relative cursor-default group product card perspective flex gap-4 flex-col md:flex-row py-2 md:py-1 bg-transparent rounded-3xl"
        >
          <div className="w-full md:w-8/12 group-hover:scale-105">
            <img
              src={imageSrc}
              alt=""
              className="w-full h-full object-cover rounded-lg bg-transparent"
            />
          </div>
          <div className="font-semibold w-full md:w-1/4 flex flex-col justify-center items-center text-center">
            {name && <div className="text-lg font-bold mb-2">{name}</div>}
            {subTitle && (
              <div className="text-md font-semibold text-primary/70 mb-2">
                {subTitle}
              </div>
            )}
            {model && (
              <div className="text-sm italic mb-2 text-primary/50">{model}</div>
            )}
            {price !== 0 && (
              <div className="bg-shadow w-min mx-auto px-4 py-1 rounded-md text-gray-700 group-hover:scale-105 perspective">
                {"\u20B9"}&nbsp;{price}
              </div>
            )}
            {price === 0 && (
              <div className="text-sm bg-red-300 border border-red-700 w-min mx-auto px-4 py-1 rounded-md group-hover:scale-105 text-nowrap perspective">
                Out of Stock
              </div>
            )}
          </div>
          {isPopular && (
            <div className="absolute right-0 bg-orange-400 p-2 rounded-bl-3xl font-semibold text-bg perspective">
              Popular
            </div>
          )}
        </Link>
      )}
      {type === "bottom-details" && (
        <Link
          to={`/collection/${_id}/${_type}`}
          className="relative group w-full h-full flex flex-col items-center product card rounded-lg perspective px-2 pt-2 pb-5 border border-shadow"
        >
          <div className="w-full group-hover:scale-105 mb-5">
            <img
              src={imageSrc}
              alt=""
              className="w-full h-64 object-cover bg-transparent group-hover:rounded-lg group-hover:perspective"
            />
          </div>
          <div className="w-full">
            <div className="font-semibold text-lg mb-2">{name}</div>
            {price !== 0 && (
              <div className="bg-shadow w-min mx-auto px-4 py-1 rounded-md text-gray-700 group-hover:scale-105 perspective">
                {"\u20B9"}&nbsp;{price}
              </div>
            )}
            {price === 0 && (
              <div className="text-sm bg-red-300 border border-red-700 w-min mx-auto px-4 py-1 rounded-md group-hover:scale-105 text-nowrap perspective">
                Out of Stock
              </div>
            )}
          </div>
        </Link>
      )}
      {type === "images_link" && (
        <Link
          to={`/collection/${_id}/${_type}`}
          className="group product card perspective"
        >
          <div className="group-hover:scale-105">
            <img
              src={imageSrc}
              alt=""
              className="w-full h-full object-cover rounded-lg bg-transparent"
            />
          </div>
        </Link>
      )}
      {type === "images" && (
        <img
          src={imageSrc}
          alt=""
          className="w-full h-96 object-cover bg-transparent"
        />
      )}
      {type === "video" && (
        <div className="text-center justify-center items-center">
          <Heading type="heading" className="mb-5" subTitle={videoTitle} />
          <div
            className="relative overflow-hidden rounded-xl mt-10 flex items-center justify-center w-full"
            style={{ height: "auto", paddingBottom: "56.25%" }}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full rounded-xl"
              src={videoURL}
              title="video player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
              allowFullScreen
              frameborder="0"
              referrerpolicy="strict-origin-when-cross-origin"
              style={{ aspectRatio: "16/9" }} // Set aspect ratio to 16:9
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;
