import { AiOutlineClose } from "react-icons/ai";
import { BiSolidOffer } from "react-icons/bi";
import { CgDetailsMore } from "react-icons/cg";
import { FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa6";
import { GiSpeedometer } from "react-icons/gi";
import { HiOutlineMinus } from "react-icons/hi2";
import { IoIosMoon } from "react-icons/io";
import { LiaMountainSolid, LiaShippingFastSolid } from "react-icons/lia";
import { MdSunny } from "react-icons/md";
import {
  PiArrowFatLinesLeftBold,
  PiArrowFatLinesRightBold,
  PiChatTextBold,
  PiWavesBold,
} from "react-icons/pi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { SiDji } from "react-icons/si";
import { TbCircleDashed } from "react-icons/tb";
import { GoLocation, GoMail } from "react-icons/go";
import { IoLogoInstagram } from "react-icons/io5";
import { MdPhoneIphone } from "react-icons/md";

export const ICON_TYPES = {
  Logo: SiDji,
  Menu: CgDetailsMore,
  Close: AiOutlineClose,
  Moon: IoIosMoon,
  Sunny: MdSunny,
  Instagram: FaInstagram,
  Whatsapp: FaWhatsapp,
  Youtube: FaYoutube,
  ArrowLeft: PiArrowFatLinesLeftBold,
  ArrowRight: PiArrowFatLinesRightBold,
  Offer: BiSolidOffer,
  line: HiOutlineMinus,
  speed: GiSpeedometer,
  wave: PiWavesBold,
  Mountain: LiaMountainSolid,
  Payments: RiSecurePaymentLine,
  Shipping: LiaShippingFastSolid,
  CreditCard: FaRegCreditCard,
  Chat: PiChatTextBold,
  // Add more icons here as needed
};

const Icons = ({ name, size, className, onClick }) => {
  const Icon = name;
  return (
    <div className="z-10">
      {name && Icon && (
        <Icon size={size} className={className} onClick={onClick} />
      )}
      {name && !Icon && <TbCircleDashed size={size | 10} />}
    </div>
  );
};

export default Icons;
