import React, { useEffect, useState } from "react";
import {
  AnimationLayout,
  Button,
  Heading,
  ICON_TYPES,
  Icons,
} from "../../shared";
import { QUICK_CHAT } from "../../utils";

const BannerCard = ({ type, data, className, isSecond }) => {
  const { _id, _type, name, model, offer, transparentImage, subTitle } = data;

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Dynamically import the image
    const importImage = async () => {
      try {
        const module = await import(`../../assets/images/${transparentImage}`);
        // Set the image source once it's loaded
        setImageSrc(module.default);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    importImage();
  }, [transparentImage]);

  const hoverEffect = `group-hover:rotate-1 group-hover:cardHover bg-gradient-to-b from-transparent via-transparent to-shadow`;
  return (
    <>
      {type === "ads" && (
        <AnimationLayout
          type="fadeIn"
          direction="down"
          delay={0.2}
          className="group w-full h-full relative perspective"
        >
          {/* <div className="absolute inset-0 duration-300 bg-gradient-to-b from-transparent via-transparent to-shadow/40"></div> */}
          <img
            src={imageSrc}
            className={`w-full h-80 object-none transition-all duration-700 bg-gradient ${hoverEffect}`}
            alt=""
          />
          <div
            className={`absolute bottom-0 left-0 p-8 w-full h-full flex flex-col justify-end transition-all duration-700 ${hoverEffect} ${className}`}
          >
            <div>
              <div className="text-3xl py-2">{name}</div>
              <div className="text-xl text-primary/90 pb-4">{subTitle}</div>
              {offer && (
                <p className="text-lg font-normal leading-none flex row-auto opacity-60">
                  UP TO {offer}
                  <Icons name={ICON_TYPES.Offer} className="ml-1" />
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 mt-5">
              {/* <button className="flex justify-end">Shop Now</button> */}
              <Button
                name="Shop Now"
                className={"px-8 py-2 border border-primary rounded-xl"}
                toLink={`/collection/${_id}/${_type}`}
              />
            </div>
          </div>
        </AnimationLayout>
      )}
      {type === "display" && (
        <div className="group relative h-[45vh] max-h-[60vh] md:h-[60vh] mx-auto bg-black/50 mb-5 rounded-xl overflow-hidden perspective flex flex-col-reverse justify-between">
          <div className="relative md:absolute z-0 md:z-10 bottom-0 right-0 overflow-hidden">
            <img src={imageSrc} className="h-full w-full object-cover" alt="" />
          </div>
          <div className="relative md:absolute top-10 left-10 bottom-10 flex flex-col justify-between">
            <Heading
              type="heading"
              title={name}
              modelTitle={model}
              subTitle={subTitle}
              className=""
            />
            <div className="relative z-20 pt-6">
              <Button
                name="Buy Now"
                className="group-hover:cardHover px-4 py-1 rounded-xl border border-primary"
                toLink={`/collection/${_id}/${_type}`}
              />
            </div>
          </div>
        </div>
      )}
      {type === "quickchat" && (
        <div
          className={`bg-shadow w-full flex flex-row flex-wrap items-center justify-evenly text-center gap-10 perspective ${className}`}
        >
          {QUICK_CHAT.map(({ icon, text }, index) => (
            <div key={index} className="text-center p-5">
              <Icons name={icon} size={50} className="mx-auto mb-3" />
              <span
                className="text-base font-normal"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BannerCard;
