import React from "react";
import { useNavigate } from "react-router";
import Icons from "./IconLoader";

const Button = ({ name, className, toLink, icon, iconSize }) => {
  const navigate = useNavigate();

  const handlerOnClick = () => {
    if (toLink) navigate(toLink);
  };
  return (
    <>
      <button
        type="button"
        className={`relative focus:ring-1 ring-transparent rounded-3xl px-5 py-1.5 text-center inline-flex items-center gap-2 ${className}`}
        onClick={handlerOnClick}
      >
        {name}
        {icon && <Icons name={icon} size={iconSize} className="-z-10" />}
      </button>
    </>
  );
};

export default Button;
