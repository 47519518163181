import React from "react";
import { motion } from "framer-motion";
import { fadeIn, fadeOut } from "../utils/AnimationVariants";

const AnimationLayout = ({
  children,
  className,
  type,
  direction,
  delay,
  whileHover,
  dangerouslySetInnerHTML,
}) => {
  let variants;

  switch (type) {
    case "fadeIn":
      variants = fadeIn(direction, delay);
      break;
    case "fadeOut":
      variants = fadeOut(direction, delay);
      break;
    default:
      variants = null;
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.3 }}
      className={`${className}`}
      whileHover={whileHover}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </motion.div>
  );
};

export default AnimationLayout;
