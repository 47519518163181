import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import AccessoryPage from "../Pages/Client/accessories-page";
import ServicePage from "../Pages/Client/service-page";
import ErrorPage from "../Pages/error-page";
import LoaderPage from "../Pages/loader-page";

// Lazy Components Import
const ClientLayoutPage = lazy(() => import("../Pages/client-layout-page"));
const HomePage = lazy(() => import("../Pages/Client/home-page"));
const CollectionPage = lazy(() => import("../Pages/Client/collection-page"));
const AboutUsPage = lazy(() => import("../Pages/Client/about-page"));
const ContactPage = lazy(() => import("../Pages/Client/contact-page"));
const CollectionDetailPage = lazy(() =>
  import("../Pages/Client/collection-detail-page")
);

const MaintenancePage = lazy(() => import("../Pages/maintenance-page"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LoaderPage />}>
        <ClientLayoutPage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/global",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <HomePage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/collections",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <CollectionPage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/collection/:id/:type",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <CollectionDetailPage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/accessories",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <AccessoryPage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/service",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <ServicePage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <ContactPage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/aboutus",
        element: (
          <Suspense fallback={<LoaderPage />}>
            <AboutUsPage />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/maintenance",
    element: (
      <Suspense fallback={<LoaderPage />}>
        <MaintenancePage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
]);
